import React from "react"
import { Helmet } from "react-helmet-async"
import Layout from "./layout"
import EmailSignUp from "./EmailSignUp"
import HymnListItem from "./HymnListItem"
import slugUtil from "../../slug-util"

export default (props) => {
  const tunes = props.pageContext.tunes;

  function getItemModel(tune) {
    return { href: `/tunes/${slugUtil.createSlugForTuneName(tune)}`, title: tune  }
  }

  const listItems = tunes.map(meter => {
      const model = getItemModel(meter);
      return <HymnListItem key={model.href} model={model} />
    });

  return (
    <Layout>
      <Helmet>
        <title>Tunes</title>
      </Helmet>
      <div id="home-page-hymn-lists" className="container">
        <div className="item">
          <h1>Tunes</h1>
          <ul>
            {listItems}
          </ul>
        </div>
      </div>

      <div style={{marginTop: `3em`}}>
        <EmailSignUp />
      </div>
    </Layout>
  )
}
